import defaultRoutes from "./defaultRoutes";
import buyerRoutes from "./buyerRoutes";
import sellerRoutes from "./sellerRoutes";
import adminRoutes from "./adminRoutes";
import devAdminRoutes from "./devAdminRoutes";
import toolkitRoutes from "./toolkitRoutes";
import catalogManagerRoutes from "./catalogManagerRoutes";
import demandKAMRoutes from "./demandKAMRoutes";
import bizOpsTeamRoutes from "./bizOpsTeamRoutes";
import fieldOperatorRoutes from "./fieldOperatorRoutes";
import supplierTeamAdminRoutes from "./supplierTeamAdminRoutes";
import supplierTeamAgentRoutes from "./supplierTeamAgentRoutes";
import redirectRoutes from "./redirectRoutes";

const baseRoutes = [
  {
    path: "/404",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      { path: "", component: () => import("pages/ErrorNotFound.vue") },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      { path: "", component: () => import("pages/ErrorNotFound.vue") },
    ],
  },
];

const routes = baseRoutes.concat(
  defaultRoutes,
  buyerRoutes,
  sellerRoutes,
  adminRoutes,
  devAdminRoutes,
  toolkitRoutes,
  catalogManagerRoutes,
  demandKAMRoutes,
  bizOpsTeamRoutes,
  fieldOperatorRoutes,
  supplierTeamAdminRoutes,
  supplierTeamAgentRoutes,
  redirectRoutes
);

export default routes;
